import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/user_manage.vue'
import Layout from "@/layout/Layout";
import Layout_user from "@/layout/Layout_user";

const routes = [
    //�����·�ɲ���ת�嵽��¼����
  {
    path: '/',
    name: 'Login',
    component:() => import("@/views/Login"),
    // redirect: "/login",

  },
    //����admin��¼·����layout,ͬʱ�ض����û�������
  {
    path: '/admin_login',
    name: 'Layout',
    component: Layout,
    redirect: "/admin_login/admin_main",
    //Ƕ��·��

    children:[
      //��ҳ
      {
        path:'admin_main',
        name:'admin_main',
        component:()=>import("@/views/admin_main")
      },
      {
        path:'photo_process',
        name:'photo_process',
        component:()=>import("@/views/photo_process")
      },
      //�û��������棬�������û�����Ϣ���б��ķ�ʽ����
      {
        path:'user_manage',
        name:'user_manage',
        component:()=>import("@/views/user_manage")
      },
        //ͼ����������е��û�ͼ��
      {
        path:'img_manage',
        name:'imgmanage',
        component:()=>import("@/views/img_manage")
      },
        //ģ�͹�������������ģ��
      {
        path:'model_manage',
        name:'model_manage',
        component:()=>import("@/views/model_manage")
      },
        //��ҵͼƬ����
      {
        path: "Cor_imgSave",
        name: "Cor_imgSave",
        component:()=>import("@/views/Cor_imgSave")
      }

    ]
  },
    //����user��¼·��,��½�ɹ����Զ��ض���user_main��ҳ��
  {
    path: '/user_login',
    name: 'Layout_user',
    component: Layout_user,
    redirect: "/user_login/user_main",
    //Ƕ��·��
    children:[

      {
        path:'user_main',
        name:'user_main',
        component:()=>import("@/views/user_main")
      },
      {
        path:'user_photo_process',
        name:'user_photo_process',
        component:()=>import("@/views/photo_process")
      },
      {
        path:'user_history',
        name:'user_history',
        component:()=>import("@/views/user_history")
      },

      // {
      //   path:'admin',
      //   name:'admin',
      //   component:()=>import("@/views/admin")
      // },

    ]
  },
  {
    path: '/register',
    name: 'register',
    component:() => import("@/views/Register")
  },
  {
    path: '/reset',
    name: 'reset',
    component:() => import("@/views/reset")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
